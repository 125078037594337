import { useRef, useEffect } from 'react'

export default function useLockBodyScroll() {
  const styleId = useRef('scroll-locker-style')

  function ScrollLocker({ onClickOutside, onPressEsc, overlayColor = 'rgba(0,0,0,0.1)' }) {
    useEffect(() => {
      const handleEsc = (event) => {
        if (event.key === 'Escape' && onPressEsc) {
          onPressEsc(event)
        }
      }

      document.addEventListener('keydown', handleEsc)

      return () => {
        document.removeEventListener('keydown', handleEsc)
      }
    }, [onPressEsc])

    useEffect(() => {
      let overlay = document.getElementById('scroll-locker-overlay')
      let createdOverlay = false

      if (onClickOutside && !overlay) {
        overlay = document.createElement('div')
        overlay.id = 'scroll-locker-overlay'
        Object.assign(overlay.style, {
          position: 'fixed',
          top: '0',
          left: '0',
          width: '100vw',
          height: '100vh',
          backgroundColor: overlayColor,
          zIndex: '999',
        })
        overlay.onclick = onClickOutside
        document.body.appendChild(overlay)
        createdOverlay = true
      }

      return () => {
        if (createdOverlay && overlay && document.body.contains(overlay)) {
          document.body.removeChild(overlay)
        }
      }
    }, [onClickOutside, overlayColor])

    return (
      <style id={styleId.current}>
        {`
          body {
            overflow: hidden !important;
          }
        `}
      </style>
    )
  }

  return { ScrollLocker }
}
